<template>
  <div class="home">
    <Navbar />
    <Welcome
      :deskripsi="layout.deskripsi"
      :nama_sekolah="layout.nama_sekolah"
    />

    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="@/assets/img/usu.jpg" class="d-block w-100" />
        </div>
        <div class="carousel-item">
          <img src="@/assets/img/smansa.jpg" class="d-block w-100" />
        </div>
        <div class="carousel-item">
          <img src="@/assets/img/wasbang.jpg" class="d-block w-100" />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <div class="container py-3 py-md-5 val-pro">
      <div class="row">
        <div class="col-md-6">
          <h2 class="header">Prakata Kepala Sekolah</h2>
          <p>
            {{ layout.prakata_kepsek }}
          </p>
        </div>
        <div class="col-md-6">
          <h2 class="header">Program Sekolah</h2>
          <p>
            {{ layout.program_sekolah }}
          </p>
        </div>
      </div>
    </div>

    <div class="val-pro-2">
      <div class="container text-center">
        <h2 class="text-capitalize header-center">
          Apa saja jurusan yang ada?
        </h2>
        <div class="row py-3 mt-3 mt-md-4 justify-content-center">
          <div class="col-md-6 mb-3 col-lg-4">
            <div class="card text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                fill="currentColor"
                class="bi bi-thermometer card-img-top mt-4 mb-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                <path
                  d="M8 0a2.5 2.5 0 0 0-2.5 2.5v7.55a3.5 3.5 0 1 0 5 0V2.5A2.5 2.5 0 0 0 8 0zM6.5 2.5a1.5 1.5 0 1 1 3 0v7.987l.167.15a2.5 2.5 0 1 1-3.333 0l.166-.15V2.5z"
                />
              </svg>
              <div class="card-body">
                <h4 class="card-title">Sekolah Menengah Atas <br />( SMA )</h4>
                <p class="card-text mt-3 px-4">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-3 col-lg-4">
            <div class="card text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                fill="currentColor"
                class="bi bi-laptop card-img-top mt-4 mb-2"
                viewBox="0 0 16 16"
              >
                <path
                  d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z"
                />
              </svg>
              <div class="card-body">
                <h4 class="card-title">
                  Teknologi Informasi dan Komunikasi <br />
                  ( TKJ )
                </h4>
                <p class="card-text mt-3 px-4">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-3 col-lg-4">
            <div class="card text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                fill="currentColor"
                class="bi bi-tools card-img-top mt-4 mb-2"
                viewBox="0 0 16 16"
              >
                <path
                  d="M1 0L0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.356 3.356a1 1 0 0 0 1.414 0l1.586-1.586a1 1 0 0 0 0-1.414l-3.356-3.356a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0zm9.646 10.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708zM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11z"
                />
              </svg>
              <div class="card-body">
                <h4 class="card-title">
                  Teknik Kendaraan Ringan <br />( TKR )
                </h4>
                <p class="card-text mt-3 px-4">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row py-3">
        <div class="col-md-6 col-lg-8 mb-4">
          <h2 class="header"><i class="bi-newspaper"></i> Berita Terbaru</h2>
          <div class="row mt-4">
            <div class="col-lg-6 mb-3" v-for="news in news" :key="news.id">
              <CardBerita
                :title="news.title"
                :thumbnail="news.thumbnail"
                :published="news.published"
                :slug="news.slug"
              ></CardBerita>
            </div>

            <router-link
              class="text-center text-decoration-none text-dark"
              to="/berita"
              >See more &gt;</router-link
            >
          </div>

          <h2 class="header mt-3">
            <i class="bi-card-list"></i> Info Kegiatan
          </h2>
          <div class="row mt-4">
            <div
              class="col-lg-6 mb-3"
              v-for="activity in activities"
              :key="activity.id"
            >
              <CardKegiatan
                :title="activity.title"
                :thumbnail="activity.thumbnail"
                :published="activity.published"
                :slug="activity.slug"
              />
            </div>
            <router-link
              class="text-center text-decoration-none text-dark"
              to="/kegiatan"
              >See more &gt;</router-link
            >
          </div>
        </div>

        <div class="col-md-6 col-lg-4">
          <h2 class="header"><i class="bi-calendar-check"></i> Agenda</h2>
          <div class="row mt-4">
            <div class="col-12 mb-3" v-for="agenda in agenda" :key="agenda.id">
              <CardAgenda
                :title="agenda.title"
                :slug="agenda.slug"
                :location="agenda.location"
                :shortText="agenda.shortText"
                :published="agenda.published"
              />
            </div>
            <router-link
              class="text-center text-decoration-none text-dark"
              to="/agenda"
              >See more &gt;</router-link
            >
          </div>

          <!-- <h2 class="header mt-2"><i class="bi-tags"></i> Tags</h2>
          <div class="row mt-4">
            <div class="col mb-3">
              <ButtonTags />
              <ButtonTags />
              <ButtonTags />
              <ButtonTags />
              <ButtonTags />
            </div>
            <router-link
              class="text-center text-decoration-none text-dark"
              to="/tags"
              >See more &gt;
            </router-link>
          </div> -->
        </div>
      </div>
    </div>

    <Hubungi />
    <Footer :tentang="layout.tentang_sekolah" />
  </div>
</template>

<script>
import axios from "axios";
// @ is an alias to /src
export default {
  name: "Home",
  components: {
    Navbar: () => import("@/components/Navbar.vue"),
    Welcome: () => import("@/components/Welcome.vue"),
    CardBerita: () => import("@/components/CardBerita.vue"),
    CardAgenda: () => import("@/components/CardAgenda.vue"),
    CardKegiatan: () => import("@/components/CardKegiatan.vue"),
    // ButtonTags: () => import("@/components/ButtonTags.vue"),
    Hubungi: () => import("@/components/Hubungi.vue"),
    Footer: () => import("@/components/Footer.vue"),
  },
  data() {
    return {
      news: [],
      activities: [],
      layout: [],
      agenda: [],
    };
  },

  mounted() {
    this.getLayout();
    this.getNews();
    this.getAgenda();
    this.getActivity();
  },

  methods: {
    async getNews() {
      let response = await axios.get("news/4");
      this.news = response.data.data;
    },
    async getActivity() {
      let response = await axios.get("activity/4");
      this.activities = response.data.data;
    },
    async getLayout() {
      let response = await axios.get("layout");
      this.layout = response.data.data;
    },
    async getAgenda() {
      let response = await axios.get("agenda/3");
      this.agenda = response.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variable.scss";
.val-pro {
  p {
    margin-top: 1em;
    font-size: 1em;
    font-weight: 400;
    line-height: 27px;
    color: $textColor;
  }
}
.val-pro-2 {
  .card {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    .card-title {
      line-height: 26px;
      word-wrap: break-word;
    }
    .card-text {
      font-weight: 400;
      color: $textColor;
    }
  }
}
</style>
